<template>
<div class="image-bck1"> 
  <div class="wrapper"> 
    <div class="main-panel">
      <TopNavbarLanding />
      <div class="container-fluid merchant-container" style="background: rgb(224, 229, 249)">
        <div class="row">
          <div>
            <div>

                <transition name="fade" mode="out-in">
                <!-- your content here -->
                <router-view></router-view>
                </transition>
            </div>
          </div>
        </div>
      </div>
      <component :is="component" class="footer-container typefooter-1" style="margin-top: 0px"></component>
    </div>
  </div>
</div>
</template>
<script>
import TopNavbarLanding from './MerchantDashboard/TopNavbarlanding.vue'
import DashboardContent from './MerchantDashboard/Content.vue'
import ContentFooter from './MerchantDashboard/ContentFooter.vue'
export default {
  components: {
    TopNavbarLanding,
    DashboardContent,
    ContentFooter
  },
  data() {
    return{
      component: ""
    }
  },
  created() {
    this.getFooter();

  },
  methods: {
    getFooter() {
        if(this.$route.name == "Merchant Landing New") {
          this.component = ContentFooter;
        }else if(this.$route.name == "Merchant Success"){
          this.component = ContentFooter;
        }else if(this.$route.name == "Merchant Contact Us"){
          this.component = ContentFooter;
        }else if(this.$route.name == "Merchant About Us"){
          this.component = ContentFooter;
        }else{
          this.component = "";
        }
    }
  }
}
</script>
<style>
.merchant-container{
  width: 100% !important;
}
.navbar-logo{
  padding-bottom: 10px;
}
    .image-bck1{
      background: white;
        /* background-image: url('https://res.cloudinary.com/persystance-pvt/image/upload/v1578481755/co4i2kfnuh1zdhsjjebb.png'); */
        height: 100vh;
        width: 100%;
    }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
@media (max-width: 700px) {
  .image-bck{
        background-image: url('https://res.cloudinary.com/persystance-pvt/image/upload/v1578304210/nnnwhlykbbqjzfzqioxk.jpg');
        height: 100Vh;
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
    }
}
@media (min-width: 768px){
  .classWell {
    min-height: 80vh;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
}
@media (max-width: 1024px) and (min-width: 768px){
  .classWell {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 1280px) {
  .classWell {
    min-height: 85.6vh
  }
}
@media (min-width: 1440px) {
  .classWell {
    min-height: 76vh;
  }
}
@media (min-width: 1800px) {
  .classWell {
    min-height: 38vh;
  }
}
@media (min-width: 1858px) {
  .classWell {
    min-height: 71vh;
  }
}
@media (min-width: 1880px) {
  .classWell {
    min-height: 38vh;
  }
}
@media (min-width: 2880px) {
  .classWell {
    min-height: 38vh;
  }
}
</style>