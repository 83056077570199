<template>
<div>
   <div id="wrapper" class="wrapper-fluid banners-effect-3">
    <header id="header" class="typeheader-1">

        <div class="header-top hidden-compact">
            <div class="container">
                <div class="row">
                    <div class="header-top-left col-lg-7 col-md-8 col-sm-6 col-xs-4">
                        <!-- <div class="hidden-md hidden-sm hidden-xs welcome-msg">Welcome to SuperMarket! Wrap new offers / gift every single day on Weekends - New Coupon code: <span>H2020PP01</span> </div>        -->
                    </div>
                    <div class="header-top-right col-lg-5 col-md-4 col-sm-6 col-xs-8">
                        <ul class="top-link list-inline lang-curr">
                        <!-- <li class="sellerLog hidden-md hidden-sm hidden-xs">
                            <div class="btn-group currencies-block">
                                <a @click="merchantLanding">New&nbsp;Arrivals</a>
                            </div>                              
                        </li> -->
                        <!-- <li class="sellerLog hidden-md hidden-sm hidden-xs">
                            <div class="btn-group currencies-block">
                                <a @click="merchantLanding">Most&nbsp;Viewed</a>
                            </div>                              
                        </li> -->
                        <!-- <li class="sellerLog">
                            <div class="btn-group currencies-block">
                                <a @click="deliverLanding"> Delivery&nbsp;Partner</a>
                            </div>                              
                        </li> -->
                        <!-- <li class="sellerLog">
                            <div class="btn-group currencies-block">
                                <a @click="merchantLanding"> Sell&nbsp;on&nbsp;Cochchi</a>
                            </div>                              
                        </li> -->
                        
                        <li class="sellerLog">
                            <div class="btn-group currencies-block">
                                <a @click="handleByOnSlt"> Buy on Cochchi</a>
                            </div>                              
                        </li>

                        <!-- <li class="currency hidden-sm hidden-xs">
                            <div class="btn-group currencies-block">
                                <form action="index.html" method="post" enctype="multipart/form-data" id="currency">
                                    <a class="btn btn-link dropdown-toggle" data-toggle="dropdown">
                                        <span class="icon icon-credit "></span>&nbsp;&nbsp;LKR&nbsp;&nbsp;<span class="fa fa-angle-down"></span>
                                    </a>
                                    <ul class="dropdown-menu btn-xs">
                                        <li> <a >Sri Lankan Rupees - LKR</a></li>
                                    </ul>
                                </form>
                            </div>
                        </li>   
                        <li class="language hidden-sm hidden-xs">
                            <div class="btn-group languages-block ">
                                <form action="index.html" method="post" enctype="multipart/form-data" id="bt-language">
                                    <a class="btn btn-link dropdown-toggle" data-toggle="dropdown">
                                        <img src="assets/image/catalog/flags/gb.png" alt="English" title="English">
                                        <span class="">&nbsp;&nbsp;English&nbsp;&nbsp;</span>
                                        <span class="fa fa-angle-down"></span>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li><a><img class="image_flag" src="assets/image/catalog/flags/gb.png" alt="English" title="English" />&nbsp;&nbsp;English </a></li>
                                    </ul>
                                </form>
                            </div>
                        </li> -->
                    </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="merch-header-middle" class="header-middle">
            <div class="container-fluid">
                <div class="row" style="display: flex;flex-wrap: wrap;">
                    <div  style="padding-top: 0px;" id="merchantLogoBg" class="navbar-logo mer-reg-nav col-lg-4 col-md-4 col-sm-5 col-xs-12">
                        <div class="logo">
                            <a style="cursor: default;" @click="handleByOnSltM">
                                <img id="mer-reg-log" :src="this.$store.state.colourLogo" alt="Your Store" />
                            </a>
                        <!-- <span class="Rectangle-13"> <span class="Rectangle-text"> MERCHANT CENTRE </span> </span> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-4  col-xs-6 merchant-landing--righttext-wpr">
                        <div class="merchant-landing--righttext-wpr--inner">
                            <a href="./HelpCenterHome.html"><p class="text-color-headder" style="font-size: 18px; font-weight: 600;">Cochchi.lk Merchant Help Center</p></a>
                        </div>
                    </div>
                    <div id="mer-right-img" class="col-lg-2 col-md-2 col-sm-3  col-xs-2 merchant-landing--righttext-wpr">
                        <img src="https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629971358/cochchi/mercahnt/1629971355957.png" alt="Your Store" />
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="header-bottom hidden-compact" style="outline: 1px solid red;">
            <div class="container">
                <div class="row">   
                    <div class="bottom3" style="outline: 1px solid green;">                        
                        <div class="telephone" style="outline: 1px solid red;">
                            <ul class="signin-link blank"> 
                                <li style="margin-right: 5px;"> -->
                                    <!-- <a href="./HelpCenterHome.html" target="_blank"> -->
                                        <!-- <i class="fa fa-comments text-color-headder"></i>&nbsp;&nbsp; -->
                                        <!-- <B class="text-color-headder">Cochchi.lk Merchant Help Center</B> -->
                                    <!-- </a> -->
                                <!-- </li> -->
                                <!-- <li v-if="this.$store.state.merchant_accessToken !== null"><a @click="pushMerchantProfile"><i class="fa fa-user"></i>Profile</a></li>  -->
                                <!-- <li><a href="#"><i class="fa fa-phone-square"></i>Hotline (+94) 123 456 789</a></li>  -->
                            <!-- </ul> -->
                        <!-- </div> -->
                        <!-- <div class="signin-w hidden-md hidden-sm hidden-xs" v-if="this.$store.state.merchant_accessToken === null">
                            <ul class="signin-link blank">                            
                                <li class="log login"><i class="fa fa-lock"></i> <a class="link-lg" @click="login"> Login </a> or <a @click="register">Register</a></li>                                
                            </ul>
                        </div> -->
                        <!-- <div class="signin-w hidden-md hidden-sm hidden-xs" v-if="this.$store.state.merchant_accessToken != null">
                            <ul class="signin-link blank">                            
                                <li class="log login"><i class="fa fa-unlock"></i> <a class="link-lg" @click="logout"> Log Out</a></li>                                
                            </ul>                       
                        </div>                   -->
                    <!-- </div>        
                    
                </div>
            </div>
        </div> -->
    </header>
    </div>
</div>
</template>
<script>
export default {
  name: 'TopHeader',
  data(){
      return{
          loggedin:false
      }
  },
  methods:{
      handleByOnSlt: function() {
        this.$router.push('/').catch(err => {});
      },
      handleByOnSltM: function() {
        this.$router.push('merchant-landing-new').catch(err => {});
      },
      handleByOnSltlanding: function() {
        this.$router.push('merchant-landing').catch(err => {});
      },
      handleMerchantProfile: function() {
          this.$router.push('profile-configure').catch(err => {});
      },
      login(){
          this.$router.push('/merchant-login').catch(err => {});
      },
      logout(){
          this.$store.dispatch("logoutMerchant");
          this.$router.push('/').catch(err => {});
      },
      deliverLanding(){
        this.$router.push('/deliver-login').catch(err => {});
      },
      register(){
          this.$router.push('/merchant-register').catch(err => {});
      },
      pushMerchantProfile() {
        this.$router.push('/merchant-profile').catch(err => {});                 
      },
      merchantLanding(){
        this.$router.push('/merchant-profile').catch(err => {});
      },
  }
}
</script>

<style scoped> 
#header{
    margin-bottom: 0px;
}
.Rectangle-13 {
    position: absolute;
    width: 84px;
    height: 19px;
    border-radius: 3px;
    background-color: #ffcd05;
    line-height: 16px;
    /* padding-left: 3px;
    padding-left: 3px; */
    /* margin-top: 20px; */
    bottom: 0;
    margin-left: 7px;
    text-align: center;
}
.Rectangle-text {
  width: 71px;
  height: 10px;
  /* font-family: Roboto; */
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #005baa;
}
.mer-reg-nav{
    padding-top: 20px;
}
.top-link li a:hover{
    color: #ffd338;
}
.header-middle{
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
    background: url("/assets/image/bg/merchant-header@2x_bk.png") no-repeat center center;

}

#merch-header-middle{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background: rgb(0,104,181);
  background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%);
  /* background: url("https://res.cloudinary.com/persystance-network/image/upload/v1608181638/cochchi/portal_user/1608181638698.png") no-repeat center center !important; */
}
#moblie-right-img {
    display: none;
}

#merchantLogoBg{
  background: url("https://res.cloudinary.com/persystance-network/image/upload//q_auto,f_auto/v1608183515/cochchi/portal_user/1608183514902.png") no-repeat left center !important;
}

#mer-reg-log {
    max-width: 23%;
    margin-left: 3%;
}

#mer-right-img img {
    max-width: 100%;
}

.merchant-landing--righttext-wpr{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: flex-end;
}
.merchant-landing--righttext-wpr .merchant-landing--righttext-wpr--inner a{
    font-size: 14px;
}
@media screen and (max-width: 1600px) {
    #mer-reg-log {
        max-width: 23%;
        margin-left: 7%;
    }
}
@media screen and (max-width: 1600px) {
    #mer-reg-log {
        max-width: 23%;
        margin-left: 10%;
    }
}
@media screen and (max-width: 1200px){
    #mer-reg-log {
        max-width: 23%;
        margin-left: 14%;
    }

    .header-top-left{
        width: 25%;
    }
    .header-top-right{
        width: 75%;
    }
}
@media screen and (max-width: 994px){
    #mer-reg-log {
        max-width: 23%;
        margin-left: 14%;
        float: left;
    }
}
@media screen and (max-width: 992px){

}

@media screen and (max-width: 768px){
    .mer-reg-nav{
        text-align: center !important;
    }

    #moblie-right-img {
        display: block;
        float: right;
        position: absolute;
        right: 0px;
        height: 100%;
    }

    #mer-right-img {
        display: none;
    }

    #mer-reg-log {
        max-width: 35%;
        margin-left: 10%;
    }

    .mer-reg-nav .logo img{
        display: inline-block;
        width: 50%;
    }
    .mer-reg-nav .Rectangle-13{
        position: relative;
    }
    .merchant-landing--righttext-wpr{
        flex-basis: 100%;
        justify-content: center;
        margin-top: 12px;
    }
    .merchant-landing--righttext-wpr .merchant-landing--righttext-wpr--inner{
        text-align: center;
    }
}

@media screen and (max-width: 767px){
    #mer-reg-log {
        max-width: 14%;
        margin-left: 5%;
    }
}

@media screen and (max-width: 550px){
    #mer-reg-log {
        margin-left: 50px;
        max-width: 20%;
    }

}

@media screen and (max-width: 475px){
    #merchantLogoBg {
        background: white !important;
        text-align: center;
    }
    .logo a {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    #mer-reg-log {
        margin-left: 0px;
    }
}

@media screen and (max-width: 375px){
    .mer-reg-nav .logo img{
        width: 65%;
    }

    #mer-reg-log {
        max-width: 25%;
    }


    #ml-top-banner .login-form-container img{
        width: 60%;
    }
}

.text-color-headder {
  color: #ffffff;
}

.text-color-headder:hover {
  color: #000000;
}
</style>

