<template>
  <!-- <div class="content well classWell" style="background:white;"> -->
  <div
    class="content well"
    v-bind:class="{ landing_inner: landing }"
    style="padding: 15px; padding-bottom: 0px; margin-top: 0px"
  >
    <transition name="fade" mode="out-in">
      <!-- your content here -->
      <router-view v-model="selected_store"></router-view>
      <!-- You can change your address here. -->
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  data: function () {
    return {
      selected_store: "",
    };
  },
  watch: {
    $route(to, from) {
      this.hanldeTopNav();
    },
    value(val) {
      this.selected_store = val;
    },
  },
  onIdle() {
    this.$swal
      .fire({
        title: "Your session is about to expire",
        text:
          "You will be logged out in 10 seconds. Do you want to stay signed in?",
        type: "warning",
        confirmButtonText: "Yes, keep me signed in!",
        timer: 10000,
      })
      .then((result) => {
        if (result.value) {
        } else {
          localStorage.removeItem("order_group_id");
          localStorage.removeItem("pending_order_group_id");
          localStorage.removeItem("cancelled_order_group_id");
          this.$store.dispatch("logoutMerchant");
          this.$router.push("/merchant-landing-new") ;
        }
      });
  },
  created() {
    this.hanldeTopNav();
  },
  methods: {
    hanldeTopNav() {
      if (this.$route.name === "Merchant Landing New") {
        this.landing = true;
      } else {
        this.landing = false;
      }
    },
  },
};
</script>
<style scoped>
.well {
  background-color: transparent !important;
}
.landing_inner {
  padding: 0px !important;
  border: none !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
@media (min-width: 768px) {
  .classWell {
    min-height: 80vh;
  }
}
@media (min-width: 1280px) {
  .classWell {
    min-height: 85.6vh;
  }
}
@media (min-width: 1440px) {
  .classWell {
    min-height: 76vh;
  }
}
@media (min-width: 1800px) {
  .classWell {
    min-height: 38vh;
  }
}
@media (min-width: 1858px) {
  .classWell {
    min-height: 71vh;
  }
}
@media (min-width: 1880px) {
  .classWell {
    min-height: 38vh;
  }
}
@media (min-width: 2880px) {
  .classWell {
    min-height: 38vh;
  }
}
</style>
