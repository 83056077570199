<template>
<div>
  <div class="row" style="display: flex; justify-content: space-around;">
    <div class="offset-md-2 col-md-2 col-sm-3" v-for="feature in features" :key="feature.title">
      <div style="text-align:center; padding:30px;">
        <img v-bind:src="feature.image" width="80" height="80" class="img-responsive"><br/>
        <h4>{{feature.title}}</h4>
        <span class="text-muted">{{feature.description}}</span>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'UncommonFeatures',
  data: function () {
    return {
      features: [
          // {image:'assets/image/catalog/banners/Icons/secure.png',title:'Great Value',description:'Lorem Ipsum is simply dummy text'},
          {image:'assets/image/catalog/banners/Icons/value.png',title:'Great Value'},
          {image:'assets/image/catalog/banners/Icons/secure.png',title:'Secure Payment'},
          {image:'assets/image/catalog/banners/Icons/support.png',title:'24 x 7 Help'},
          {image:'assets/image/catalog/banners/Icons/delivery.png',title:'Door Step Delivery'}
          // {image:'assets/image/catalog/banners/Icons/store.png',title:'Open Store'},
          // {image:'assets/image/catalog/banners/Icons/touch.png',title:'Stay Touched'}
      ]
    }
  }
}
</script>
<style>
.static-cates ul li {
    padding: 0 15px;
    float: left;
    /* width: 16.66%; */
}

.static-cates ul li h4 {
margin-top:15px;
}
</style>
